@import "src/themes/mojito/styles/index.scss";
.templates--editor {
  &__card {
    width: 162px;
    height: 251px;

    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 12px;
    background-color: getvar($colors, "neutral", "white");

    @include sm {
      max-width: 218px;
      min-width: 200px;
    }

    &__body {
      display: flex;
      width: 93px;
      height: 112px;
      cursor: pointer;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      border: 1px solid getvar($colors, "neutral", "300");
    }

    &__image-wrapper {
      display: flex;
      width: 100%;
      height: 140px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      canvas {
        width: 77px;
      }
    }

    &__qr {
      border-radius: space(0.25);
      canvas {
        width: 69px;
      }

      &.no-frame {
        canvas {
          margin-top: 5px;
        }
      }
      &--framed {
        display: flex;
        justify-content: center;
        display: flex;
        justify-content: center;
        border-radius: 0.25rem;
        cursor: pointer;
        padding: 0.375rem;
        height: 7rem;
        width: 7rem;
        svg {
          width: 68px;
          height: 140px;
          margin-top: -13px;
        }
        canvas {
          height: 53px;
          width: 57px;
        }
        &.black {
          svg {
            margin-top: -8px;
          }
        }
        &.hand,
        &.hairsalon {
          svg {
            width: 67px;
          }
        }
        &.waiter {
          canvas {
            width: 51px;
            position: relative;
            top: -3px;
            left: 0px;
          }
        }
        &.eyelashes,
        &.hairsalon {
          canvas {
            width: 54px;
          }
        }
        &.beauty {
          canvas {
            height: 39px;
            width: 38px;
          }
        }
        &.care {
          canvas {
            height: 43px;
            width: 44px;
          }
        }
        &.bucket,
        &.takeaway {
          canvas {
            height: 47px;
            width: 46px;
          }
        }
        &.cup,
        &.asian,
        &.cards,
        &.bouquet,
        &.cart {
          canvas {
            height: 40px;
            width: 40px;
          }
        }
        &.brochure,
        &.menu {
          canvas {
            height: 43px;
            width: 40px;
          }
        }
        &.soda {
          canvas {
            height: 44px;
            width: 43px;
          }
        }
        &.restaurant {
          canvas {
            height: 31px;
            width: 32px;
          }
        }
        &.wine {
          canvas {
            height: 35px;
            width: 35px;
          }
        }
        &.headphones,
        &.mic {
          canvas {
            height: 40px;
            width: 43px;
          }
        }
        &.concert {
          canvas {
            height: 50px;
            width: 50px;
          }
        }
        &.videogame,
        &.movie {
          canvas {
            height: 42px;
            width: 44px;
          }
        }
        &.music,
        &.mp3,
        &.like,
        &.ticket2 {
          canvas {
            height: 44px;
            width: 44px;
          }
        }
        &.film {
          canvas {
            height: 25px;
            width: 26px;
          }
        }
        &.hands {
          canvas {
            height: 49px;
            width: 48px;
          }
        }
        &.chat,
        &.partyhat {
          canvas {
            height: 41px;
            width: 42px;
          }
        }
        &.stars {
          canvas {
            height: 43px;
            width: 42px;
          }
        }
        &.review {
          canvas {
            height: 43px;
            width: 41px;
          }
        }
        &.wedding,
        &.party,
        &.balloons,
        &.garland,
        &.hand {
          canvas {
            height: 48px;
            width: 48px;
          }
        }
        &.christmas {
          canvas {
            height: 39px;
            width: 39px;
          }
        }
        &.tickets {
          canvas {
            height: 46px;
            width: 47px;
          }
        }
        &.celebration {
          canvas {
            height: 39px;
            width: 40px;
          }
        }
        &.giftbox {
          canvas {
            height: 43px;
            width: 43px;
          }
        }
        &.shoppingbag {
          canvas {
            height: 44px;
            width: 42px;
          }
        }

        img {
          height: 40px;
        }

        &.shopping,
        &.museum,
        &.ticket {
          img {
            height: 52px;
          }
        }

        &.black,
        &.mobile,
        &.ticket,
        &.white {
          img {
            height: 62px;
          }
        }
        &.buttonFrame {
          img {
            height: 61px;
          }
        }
        &.restaurant {
          img {
            height: 30px;
          }
        }
        &.wine {
          img {
            height: 35px;
          }
        }
        &.film {
          img {
            height: 25px;
          }
        }

        &.receipt,
        &.museum {
          img {
            height: 60px;
          }
        }
        &.envelope {
          img {
            height: 54px;
          }
        }

        &.shopping,
        &.board {
          img {
            height: 56px;
          }
        }
        &.cafe {
          img {
            height: 43px;
          }
        }
        &.eyelashes,
        &.hairsalon {
          img {
            height: 54px;
          }
        }

        &.shopping {
          img {
            height: 56px;
          }
        }
        &.waiter,
        &.concert,
        &.hand,
        &.wedding,
        &.balloons,
        &.garland {
          img {
            height: 48px;
          }
        }

        canvas {
          display: block;
          margin: 0px !important;
        }

        &.receipt {
          canvas {
            height: 61px;
            width: 59px;
          }
        }
        &.envelope {
          canvas {
            height: 54px;
            width: 54px;
          }
        }
        &.board {
          canvas {
            height: 56px;
            width: 55px;
          }
        }
        &.shopping {
          canvas {
            height: 57px;
            width: 56px;
          }
        }
        &.cafe {
          canvas {
            height: 43px;
            width: 44.7px;
          }
        }
        &.museum {
          canvas {
            height: 60px;
            width: 58px;
          }
        }
        &.ticket {
          canvas {
            height: 62px;
            width: 62px;
          }
        }
        &.buttonFrame {
          canvas {
            height: 61.1px;
            width: 61.3px;
          }
        }
        &.white {
          canvas {
            height: 62px;
            width: 61.1px;
          }
        }
        &.mobile,
        &.black {
          canvas {
            height: 63px;
            width: 61.3px;
          }
        }
      }
    }
  }
}

.selected {
  outline: 2px solid getvar($colors, "primary", "500");
}
